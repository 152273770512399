import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [3],
		"/auth": [4],
		"/bots": [~5],
		"/bots/new": [11],
		"/bots/[bot_id]/config": [6,[2]],
		"/bots/[bot_id]/conn": [~7,[2]],
		"/bots/[bot_id]/knowledge": [~8,[2]],
		"/bots/[bot_id]/link_knowledge_source": [~9,[2]],
		"/bots/[bot_id]/servers": [~10,[2]],
		"/knowledge_sources": [~12],
		"/knowledge_sources/new": [16],
		"/knowledge_sources/new/document": [17],
		"/knowledge_sources/new/faq": [18],
		"/knowledge_sources/new/text": [19],
		"/knowledge_sources/[knowledge_source_id]": [~13],
		"/knowledge_sources/[knowledge_source_id]/edit": [~14],
		"/knowledge_sources/[knowledge_source_id]/faq": [~15],
		"/tags": [20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';